<template>
    <div class="eatDesktimeOrderDetail defaultReport">
        <div class="content-box clearfix">
            <div class="panel-box top-info-box">
                <el-row>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <span class="lable-text">统计日期：{{data?.Rpt_Date}} </span>
                    </el-col>
                </el-row>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="table-box grey-table isFooter" ref="tableBox">
                <el-table class="el-table--scrollable-y"  :data="tableListPage" ref="elTable"
                 border 
                 show-summary
                :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed type="index" label="序号" width="42">
                        <template #default="scope">
                            {{scope.$index+pagesize*(currentPage-1)+1}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_RoomName" label="桌号评分" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_PhaseName" label="营业日期" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="餐厅" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="时段" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_DeskName" label="桌号" min-width="70">
                        <template #default="scope">
                            <span class="link" >{{scope.row.Eat_DeskName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="标准时长/分" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="起菜计时人" min-width="60"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="起菜时间" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="上齐操作人" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="上齐时间" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="上齐时长/分" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="达标值" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="取消上菜次数" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="桌数" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="OnDeskMinutes" label="结账单号" min-width="70" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="销售渠道" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="消费类型" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="开台人" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="开台时间" min-width="60"></el-table-column>
                    <el-table-column prop="Eat_CheckIndex" label="结账时间" min-width="60"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        
     </div>
</template>
<script>

/**账单表—总桌数  账单表—正常桌数  账单表—超时桌数  账单表—未完成考核桌数 */
export default {
    name:'reportEatAnalysis',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.GetBillCheck();
                   })
                }
            },
            immediate:true,
        }
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){
                let name="按时间分析—正常桌数";//按时间分析—正常桌数  按时间分析—超时桌数  按上菜顺序分析—正常桌数  按上菜顺序分析—异常桌数
                if(this.data?.type==2){
                    name="按时间分析—超时桌数";
                }else if(this.data?.type==3){
                    name="按上菜顺序分析—正常桌数";
                }else if(this.data?.type==4){
                    name="按上菜顺序分析—异常桌数";
                }
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:name,
                    list:this.tableList,
                    headArr:[
                        [
                            {value:'统计日期：'+this.data?.Rpt_Date,col:3}, 
                        ],
                    ]
                })
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            this.businessHours=[this.userInfo.Rpt_Date,this.userInfo.Rpt_Date]
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date,
                DrillDown_Type:this.data?.type
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodServingOrderDrillDownReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.currentPage=1
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="Eat_DeskName"){//穿透 上菜顺序速度明细表
                this.$emit("addFrame",{
                    key:"eatserveDetail"+this.data?.Rpt_Date+row.Eat_AutoID,
                    data:{
                        Rpt_Date:this.data?.Rpt_Date,
                        Eat_AutoID:row.Eat_AutoID
                    },
                    title:'上菜顺序速度明细表',
                    url:'reportEatserveDetail'
                });
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            columns.forEach((column, index) => {
                if(column.property=="Eat_DeskName"){
                    sums[index]=this.tableList.length+"桌";
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        }
    }
}
</script>

<style lang="scss">
@import './eatDesktimeOrderDetail.scss'
</style>